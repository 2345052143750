import styled from "styled-components";

export const ErrorContainer = styled.div``;

export const Title = styled.h1`
  /* margin-top: 100px; */
  font-family: Palatino;
`;

export const Description = styled.p`
  font-family: Trebuchet MS;
`;
